export const borderRadius = '4px';

// The transition time in milliseconds used for most transitions.
export const transitionDelay = 200;

// Sizes in px
export const mediaQuerySizes = {
  small: 375,
  medium: 768,
  large: 1024,
};

export const device = {
  small: `only screen and (min-width: ${mediaQuerySizes.small}px)`,
  medium: `only screen and (min-width: ${mediaQuerySizes.medium}px)`,
  large: `only screen and (min-width: ${mediaQuerySizes.large}px)`,
};
