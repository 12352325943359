export enum colors {
  background = '#0F0F0F',
  backgroundSecondary = '#353535',
  black = '#000000',
  text = '#ffffff',
  textSecondary = '#8F989F',
  textDisabled = '#706a70',
  inputBorder = '#606060',
  selectHover = '#303030',
  inputBorderHover = '#ABABAB',
  inputBackground = '#000000',
  border = '#606060',
  linkHover = '#2788f9',
  primary = '#4993EA',
  success = '#B1DA3E',
  warning = '#E6A135',
  error = '#F01060',
}
